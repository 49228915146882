const Link = ({ setPath, selected, text }) => {
  const headerItemStyle = {
    padding: "20px",
    fontFamily: "Gentium Basic, serif",
    color: "darkgray",
    fontWeight: "600",
    fontSize: "18px",
    textDecoration: "none",
  };

  if (selected) {
    headerItemStyle.color = "black";
    headerItemStyle.borderBottom = "solid 1px";
  }

  return (
    <div onClick={setPath} style={headerItemStyle}>
      {text}
    </div>
  );
};

const NavBar = ({ path, setPath }) => {
  return (
    <div
      style={{
        display: "flex",
        padding: "15px 0 0 15px",
      }}
    >
      <Link text="Home" setPath={() => setPath("/")} selected={path === "/"} />
      <Link text="About Us" setPath={() => setPath("/about-us")} selected={path === "/about-us"} />
      <Link
        text="FAQs"
        selected={path === "/travel-details"}
        setPath={() => setPath("/travel-details")}
      />
    </div>
  );
}

export default NavBar;

import "./App.css";
import { useState } from "react";
import HomePage from "./Home";
import TravelDetails from "./TravelDetails";
import AboutUs from "./AboutUs";
import NavBar from "./components/NavBar";

function App() {
  const [path, setPath] = useState("/");

  return (
      <div style={{
        background: "linear-gradient(157.63deg, #CDE4FF -3.17%, #DABFDC 95.18%)",
        height: "110%",
        minHeight: "110vh",
      }}>
        <NavBar path={path} setPath={(path) => setPath(path)} />
        {path === '/about-us' && <AboutUs />}
        {path === '/travel-details' && <TravelDetails />}
        {path === '/' && <HomePage />}
      </div>
  );
}

export default App;

import GardenImage from "./GardenImage.js";

function HomePage() {
  return (
    <div>
      <div
        style={{
          fontSize: "60px",
          textAlign: "center",
          paddingTop: "80px",
          fontFamily: "Gentium Basic, serif",
          position: "relative",
          zIndex: "10",
          //paddingBottom: "900px"
        }}
      >
        <div>IAN VONSEGGERN</div>      
        <div >&</div>
        <div>SABRINA SIU</div>
      </div>
      <div
        style={{
          fontSize: "30px",
          textAlign: "center",
          padding: "20px",
          fontFamily: "helvetica",
          margin: "0 auto",
          maxWidth: "800px",
          position: "relative",
          zIndex: "10",
        }}
      >
        are getting married on Sunday, August 7, 2022 at The Secret Gardens in
        Bodega Bay, CA.
      </div>
      <GardenImage />
    </div>
  );
}

export default HomePage;

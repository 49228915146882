import GardenImageSVG from "./GardenImage.svg";
import GardenImageMobileSVG from "./GardenImageMobile.svg";
import "./GardenImage.css";

const GardenImage = ({}) => {
  return (
    <div 
    className="spacer"
    style={{
        marginBottom: "-50px",
    }}>
        <img
            className="bigImage"
            style={{
                position: "fixed",
                left: "-2.5%",
                bottom: "-50px",
                width: "105%",
                zIndex: "10",
            }}
            src={GardenImageSVG}
        />
        <img
            className="littleImage"
            style={{
                position: "fixed",
                left: "-2.5%",
                bottom: "-50px",
                width: "105%",
                zIndex: "10",
            }}
            src={GardenImageMobileSVG}
        />
    </div>
  );
}

export default GardenImage;
import GardenImage from "./GardenImage.js";

function TravelDetails() {
  const headerStyle = {
    paddingTop: "50px",
    paddingBottom: "20px",
    fontFamily: "Gentium Basic, serif",
    fontSize: "22px"
  }
  return (
    <div>
      <div       
        style={{
          paddingLeft: "50px",
          paddingRight: "50px",
          lineHeight: "1.5",
        }}
      >
          <div style={headerStyle}>Where should I stay?</div>
          <div>
          We are excited to get everyone together and to that end, we encourage you to stay in Petaluma. 
          We have a hotel block at the{" "} 
          <a 
            target="_blank" 
            rel="noopener noreferrer" 
            href="https://www.choicehotels.com/reservations/groups/qh92o4?checkInDate=2022-08-05&checkOutDate=2022-08-08&ratePlanCode=BUQULI"
          >
            Hotel Petaluma
          </a>. The block contains some rooms for Friday, August 5th - Monday, August 8th and some are just for Saturday, August 6th - Monday, August, 8th, so just make sure to select your desired date range via the link.
          If you'd like to stay elsewhere in Petaluma, there are also some Airbnb's and hotels which are a short walk away.
          </div>
          <br/>
          <div>
           We encourage you to stay in Petaluma because we will have welcome picnic on Saturday afternoon and a casual wedding afterparty both in Petaluma. 
          However, if you do want to stay in Bodega Bay, there are a variety of smaller hotels and Airbnb's in that area.
          </div>
          <div style={headerStyle}>Where and when is the welcome picnic?</div>
          <div>
            The welcome picnic is at Penry Park right next to Hotel Petaluma in downtown Petaluma. We will provide some blankets to 
            sit on as well as pizza and soft drinks. Please feel free to bring your own blanket and/or chairs if you would like! The
            picnic starts at 5pm on Saturday the 6th.</div>
          <div style={headerStyle}>Did you say afterparty?</div>
          <div>
            For those still going strong at 10pm, we are going to head to a bar a couple blocks from Hotel Petaluma called McNear's after the shuttle gets us back.
          </div>
          <div style={headerStyle}>How will I get to the wedding venue?</div>
          <div>
          There is parking at the venue. You can find it on Google maps at 1555 Eastshore Rd. We will also be providing shuttles to the The Secret Gardens and back to the hotel at the end of the night. We will have an open bar so if you do plan on drinking please make sure to plan a carpool or 
          take the shuttle. Note there are no Ubers or Lyfts in Bodega Bay and cell service may be spotty. 
        </div>
        <div style={headerStyle}>Which airport should I fly into?</div>
        <div>
          You can fly into San Francisco and drive to Petaluma (1 hour) or Bodega Bay (1.5 hours).
          There is a bus from SFO to Petaluma called the Airport Express if you'd rather not rent a car.
          There is also a commercial airport in Sonoma County closer to Petaluma, although it has a pretty small selection of flights.
        </div>
        <div style={headerStyle}>What time is the wedding?</div>
        <div>
          The ceremony is at 4pm and will be followed by a reception with dinner. There will be vegetarian options but please let us know if you have other dietary restrictions.
        </div>
        <div style={headerStyle}>What should I wear?</div>
        <div>
          We're looking forward to everyone looking their best, and we are planning to take lots of nice photos so dress to impress! Please come in cocktail attire -
          suits, nice dresses, slacks with blazers and a tie, skirts, and jumpsuits all welcome. And you'll want to bring your dancing shoes.
        </div>
        <br/>
        <div>
          Bodega Bay is right on the ocean and can be quite cool even in August. Dinner will be in a three sided tent, both the reception and dinner
          will have plenty of heat lamps, and there are a couple fire pits. We hope to keep you warm, but we recommend bringing a layer to stay comfortable into the night.
        </div>
        <div style={headerStyle}>Where can I find the registry?</div>
        <div>
          We are blessed to have already accumulated many of our material needs in our studio apartment so 
          we would be more than happy to accept a cash present toward our honeymoon either in person or through <a href="https://registry.theknot.com/sabrina-siu-ian-vonseggern-august-2022/52840845">The Knot</a>.
          If you would rather we also have a small selection of physical gifts listed in the registry too.
        </div>
        <div style={headerStyle}>Where and when should I RSVP?</div>
        <div>
          We did not send out physical invites, just Paperless Post invites to the email addresses we have on file for you.
          We ask that you please let us know if you can attend 
          via the form on the invite by June 20th to help us best plan!
        </div>
        <div style={headerStyle}>What precautions are being taken for COVID 19?</div>
        <div>
          Our wedding will be outdoors so thankfully we feel much more comfortable about bringing together our friends and relatives. We are asking that everyone who
          attends be vaccinated. 
        </div>
        <GardenImage />
      </div>
    </div>
  );
}

export default TravelDetails;

import GardenImage from "./GardenImage.js";
import OurFirstPhoto from "./OurFirstPhoto.png";
import SaveTheDatePhoto from "./SaveTheDatePhoto.png";

const TextBlock = ({ headingText, bodyText }) => {
  return (
    <div style={{
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "40px",
      width: "305px",
    }}>
      <div style={{
        paddingBottom: "10px",
        fontFamily: "Gentium Basic, serif",
        fontSize: "22px"
      }}>
        {headingText}
      </div>
      <div>{bodyText}</div>
    </div>
  );
}


function AboutUs() {
  const blockStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: "100px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingBottom: "0",
  };

  return (
    <div>
      <div style={{
        ...blockStyle,
        paddingBottom: "20px",
        flexWrap: "wrap-reverse",
      }}>
        <div style={{ 
          display: "flex",
          justifyContent: "center",
          paddingLeft: "40px",
          paddingRight: "40px",
        }}>
          <img
            src={OurFirstPhoto}
          />
        </div>
        <TextBlock headingText="From 2016" bodyText="We met in NYC, became friends, and fell in love." />
      </div>
      <div style={{
        ...blockStyle,
        flexWrap: "wrap",
      }}>
        <TextBlock
          headingText="To Now"
          bodyText="After countless dates, travels, and adventures, we've decided to spend the rest of our lives together. We want you to join us to celebrate!"
        />
        <div style={{ 
          display: "flex",
          justifyContent: "center",
          height: "406px",
          width: "305px",
          paddingLeft: "40px",
          paddingRight: "40px",
        }}>
          <img
            src={SaveTheDatePhoto}
          />
        </div>
      </div>
      <GardenImage />
    </div>
  );
}

export default AboutUs;
